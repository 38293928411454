import React from "react"

type Props = {
  children: React.ReactNode
  className?: string
}
const Title: React.FC<Props> = ({ children, className }) => {
  return (
    <h1
      className={`font-black text-4xl lg:text-5xl text-primary-700 leading-tight ${className}`}
    >
      {children}
    </h1>
  )
}

export default Title
