import React, { useState } from "react"

type Props = {
  question: string
  answer: string
}
const Collapsible: React.FC<Props> = ({ question, answer }) => {
  const [open, setOpen] = useState(false)

  function handleClick() {
    setOpen(prev => !prev)
  }
  return (
    <div
      className="bg-white rounded-lg shadow-md p-4 hover:bg-pink-100 cursor-pointer"
      onClick={handleClick}
    >
      <h3 className="text-primary-600 font-bold">{question}</h3>
      <p
        className={`text-gray-600 whitespace-pre-line ${open ? "" : "hidden"}`}
      >
        {answer}
      </p>
    </div>
  )
}

export default Collapsible
