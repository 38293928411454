import React from "react"
import Collapsible from "../components/Collapsible/index"
//@ts-ignore
import Logo from "../images/logo-tooth.png"
import { graphql } from "gatsby"
import SEO from "../components/Seo"
import Title from "../components/Title/index"

type Props = {
  data: {
    allContentfulFaq: {
      edges: {
        node: {
          question: string
          answer: {
            answer: string
          }
        }
      }[]
    }
  }
}
const Faq: React.FC<Props> = ({ data }) => {
  return (
    <>
      <SEO
        title="Preguntas Frecuentes"
        socialTitle="Tooth Club | Preguntas Frecuentes"
      />
      <Title className="mb-8">Preguntas Frecuentes</Title>
      {data.allContentfulFaq.edges.map(({ node }, i) => (
        <div className="mt-4" key={i}>
          <Collapsible question={node.question} answer={node.answer.answer} />
        </div>
      ))}
    </>
  )
}

export const query = graphql`
  query FAQ {
    allContentfulFaq {
      edges {
        node {
          question
          answer {
            answer
          }
        }
      }
    }
  }
`
export default Faq
